'use strict'

import queryString from 'query-string'

require('formdata-polyfill')
window.queryString = queryString
window.sscache = require('session-storage-cache')

require('smoothscroll-polyfill').polyfill();
window.__forceSmoothScrollPolyfill__ = true;

require('./humanize')
require('./geo_location')
require('./filter_processor')
require('./property')
require('./map_handler')
require('./filter')
require('./closest_polyfill')

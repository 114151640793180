var Map,
  indexOf = [].indexOf;

import ajax from './ajax';

import Marker from './marker';

Map = class Map {
  constructor(map) {
    this.clearMarkers = this.clearMarkers.bind(this);
    this.displayZeroState = this.displayZeroState.bind(this);
    if (!window.filters) {
      window.filters = {};
    }
    if (!window.markers) {
      window.markers = [];
    }
    this.setCurrentMap(map);
  }

  currentMap() {
    return this.map;
  }

  setCurrentMap(map) {
    var center;
    center = {
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng()
    };
    this.setSearchCenter(center);
    return this.map = map;
  }

  setSearchCenter(searchCenter) {
    return this.searchCenter = searchCenter;
  }

  getSearchCenter() {
    return this.searchCenter;
  }

  currentLatitude() {
    return this.getSearchCenter().lat; //@currentMap().getCenter().lat()
  }

  currentLongitude() {
    return this.getSearchCenter().lng; //@currentMap().getCenter().lng()
  }

  reverseGeocodeSearchCenter() {
    var geocoder;
    geocoder = new google.maps.Geocoder();
    return geocoder.geocode({
      'location': this.getSearchCenter()
    }, function(results, status) {
      var address, addressComponents, addressElement, clearButton, stateComponent;
      if (status === 'OK') {
        addressComponents = results[0].address_components;
        stateComponent = addressComponents.find(function(component) {
          return indexOf.call(component.types, 'administrative_area_level_1') >= 0;
        });
        window.zerostate = stateComponent['short_name'] === "MN" ? 'in-state' : "out-of-state";
        address = results[0].formatted_address;
        addressElement = document.getElementsByName('address')[0];
        addressElement.value = address;
        // show input clear button for addressElement.value results
        clearButton = addressElement.closest('.search-container').querySelector('[data-mapsearchcomponent-target="clearButton"]');
        if (clearButton) {
          clearButton.style.display = 'inline-block';
        }
        if (document.getElementById('traffic_log_original_search')) {
          document.getElementById('traffic_log_original_search').value = address;
        }
        filters['search_address'] = address;
        return map.zerostate = window.zerostate;
      }
    });
  }

  clearMarkers() {
    var i, len, marker, ref;
    ref = window.markers;
    for (i = 0, len = ref.length; i < len; i++) {
      marker = ref[i];
      marker.setMap(null);
    }
    return window.markers = [];
  }

  clearSidebar() {
    var results1, sidebar;
    sidebar = document.querySelector('#provider-info-sidebar');
    results1 = [];
    while (sidebar.hasChildNodes()) {
      results1.push(sidebar.removeChild(sidebar.lastChild));
    }
    return results1;
  }

  setBoundsForMarkers() {
    var i, index, len, marker, newBoundary, pos, ref;
    if (window.markers.length > 0) {
      this.getZeroStateContainer().style.display = "none";
      this.getOutOfStateZeroStateContainer().style.display = "none";
      newBoundary = new google.maps.LatLngBounds();
      ref = window.markers;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        marker = ref[index];
        if (index > 20) {
          break;
        }
        pos = marker.position;
        newBoundary.extend(pos);
      }
      this.currentMap().fitBounds(newBoundary);
      if (map.map.getZoom() > 16) {
        map.map.setZoom(16);
        return map.map.initialZoom = false;
      }
    } else {
      return this.displayZeroState();
    }
  }

  removeListener() {
    return google.maps.event.removeListener(window.mapChangelistener);
  }

  setCenter(lat, lng) {
    this.setSearchCenter({
      'lat': lat,
      'lng': lng
    });
    return this.currentMap().setCenter(this.getSearchCenter());
  }

  addSearchCenterMarker() {
    var icon, marker;
    icon = "/static/images/search_address_pin.png";
    marker = {
      icon: icon,
      position: this.getSearchCenter()
    };
    if (window.centerMarker) { // Clear out existing center marker
      window.centerMarker.setMap(null);
    }
    window.centerMarker = new google.maps.Marker(marker);
    return window.centerMarker.setMap(this.currentMap());
  }

  getZeroStateContainer() {
    return document.getElementById('map-zero-state');
  }

  displayZeroState() {
    var checkForZeroState;
    checkForZeroState = () => {
      if (window.zerostate != null) {
        if (window.zerostate === 'out-of-state' && !window.text_search_address) {
          this.getZeroStateContainer().classList.remove('show');
          return this.getOutOfStateZeroStateContainer().classList.add('show');
        } else {
          this.getOutOfStateZeroStateContainer().classList.remove('show');
          return this.getZeroStateContainer().classList.add('show');
        }
      } else {
        return setTimeout(checkForZeroState, 100);
      }
    };
    return checkForZeroState();
  }

  getOutOfStateZeroStateContainer() {
    return document.getElementById('map-zero-state-out-of-state');
  }

  showMapLoadingState(loading) {
    var mapContainer, mapLegend, mapLoadingState;
    mapLoadingState = document.getElementById('map-loading-state');
    mapContainer = document.getElementById('map');
    mapLegend = document.getElementById('map-legend');
    if (loading) {
      mapLoadingState.style.display = 'block';
      mapContainer.style.display = 'none';
      return mapLegend.style.display = 'none';
    } else {
      mapLoadingState.style.display = 'none';
      mapContainer.style.display = 'block';
      return mapLegend.style.display = 'flex';
    }
  }

  updateMap(forceSetBounds = false) {
    var path, query;
    this.showMapLoadingState(true);
    path = "";
    query = queryString.stringify(window.filters, {
      arrayFormat: 'bracket'
    });
    if (window.text_search_address) {
      path = `/providers?${query}&text_search=${window.text_search_address}`;
    } else {
      path = `/providers?current_latitude=${this.currentLatitude()}&current_longitude=${this.currentLongitude()}&${query}`;
    }
    if (window.markers) {
      this.clearMarkers();
    }
    return ajax.getAjax(path, (data) => {
      var i, jsonData, len, marker, provider;
      jsonData = JSON.parse(data);
      this.clearSidebar();
      for (i = 0, len = jsonData.length; i < len; i++) {
        provider = jsonData[i];
        marker = new Marker(provider, this.currentMap());
        marker.addMarkerFor(provider);
      }
      this.showMapLoadingState(false);
      this.addSearchCenterMarker();
      if (!window.mapChangelistener || forceSetBounds) {
        return this.setBoundsForMarkers();
      }
    });
  }

};

export default Map;

var Humanize;

Humanize = class Humanize {
  static value(str) {
    var frags, i, j, len, val, value;
    frags = str.split('_');
    for (i = j = 0, len = frags.length; j < len; i = ++j) {
      val = frags[i];
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return value = frags.join(' ');
  }

};

export default Humanize;

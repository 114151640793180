var FilterProcessor,
  indexOf = [].indexOf;

import Humanize from './humanize';

FilterProcessor = (function() {
  class FilterProcessor {
    constructor() {
      var address, i, key, len, ref, value;
      this.addCheckboxFilter = this.addCheckboxFilter.bind(this);
      if (!window.filters) {
        window.filters = {};
      }
      if ((address = this.queryStringToObj()['search_address'])) {
        this.applyAddressFromParams(address);
      }
      ref = Object.entries(this.queryStringToObj());
      for (i = 0, len = ref.length; i < len; i++) {
        [key, value] = ref[i];
        this.applyFilterFromParams(key, value);
      }
    }

    queryStringToObj() {
      return queryString.parse(location.search);
    }

    applyAddressFromParams(address) {
      var addressElement;
      // Apply existing search from query.
      addressElement = document.getElementsByName('address')[0];
      addressElement.value = address;
      // addressElement.parentNode.children.namedItem('address-find-button').click()
      window.filters['search_address'] = address;
      return this.geocodeAndRecenterOn(address);
    }

    applyFilterFromParams(key, value) {
      var element, elements, i, len, results1;
      elements = Array.from(document.querySelectorAll('input[type="checkbox"], input[type="radio"]'));
      results1 = [];
      for (i = 0, len = elements.length; i < len; i++) {
        element = elements[i];
        if (element.name === key && element.value === value) {
          window.filters[key] = value;
          results1.push(element.checked = true);
        } else {
          results1.push(void 0);
        }
      }
      return results1;
    }

    addCheckboxFilter(filterElm) {
      var index, shouldUpdateMap;
      map.removeListener();
      if (window.filters[filterElm.name] === void 0) {
        window.filters[filterElm.name] = [];
      }
      if (filterElm.checked && window.filters[filterElm.name].indexOf(filterElm.value) === -1) {
        window.filters[filterElm.name].push(filterElm.value);
      } else {
        index = window.filters[filterElm.name].indexOf(filterElm.value);
        if (index > -1) {
          window.filters[filterElm.name].splice(index, 1);
        }
      }
      if (window.filters[filterElm.name].length === 0) {
        delete window.filters[filterElm.name];
      }
      shouldUpdateMap = filterElm.dataset.updatemap;
      if (shouldUpdateMap === true) {
        return map.updateMap(true);
      }
    }

    setAddress(formElm, event) {
      var address;
      if (event) {
        event.preventDefault();
      }
      address = new FormData(formElm).get('address');
      window.filters['search_address'] = address;
      if (document.getElementById('traffic_log_original_search')) {
        document.getElementById('traffic_log_original_search').value = address;
      }
      return this.geocodeAndRecenterOn(address);
    }

    geocodeAndRecenterOn(address) {
      var geocoder;
      geocoder = new (google.maps.Geocoder)();
      return geocoder.geocode({
        'address': address
      }, function(results, status) {
        var addressComponents, lat, lng, loc, stateComponent;
        if (status === google.maps.GeocoderStatus.OK) {
          window.text_search_address = null;
          loc = results[0].geometry.location;
          sscache.set(address, loc);
          addressComponents = results[0].address_components;
          stateComponent = addressComponents.find(function(component) {
            return indexOf.call(component.types, 'administrative_area_level_1') >= 0;
          });
          window.zerostate = stateComponent['short_name'] === "MN" ? 'in-state' : "out-of-state";
          lat = loc.lat();
          lng = loc.lng();
          map.setCenter(lat, lng);
          map.updateMap(true);
          window.latlng = {
            lat: lat,
            lng: lng
          };
          if (document.getElementById('traffic_log_latlng')) {
            return document.getElementById('traffic_log_latlng').value = lat + "," + lng;
          }
        } else {
          window.text_search_address = address;
          return map.updateMap(true);
        }
      });
    }

  };

  window.updateMapWithCurrentFilters = function() {
    if (window.innerWidth < 992) {
      window.toggleMobileFilters();
    }
    return map.updateMap(true);
  };

  window.toggleMobileFilters = function() {
    var providerFilters;
    providerFilters = document.getElementById('provider-filters');
    if (providerFilters) {
      providerFilters.classList.toggle('mobile-filters-active');
      if (document.body.style.overflow = 'hidden') {
        return document.body.style.removeProperty('overflow');
      }
    }
  };

  window.clearFilters = function() {
    var checkboxes, filterContainer, filterCounter, filters, key;
    if (window.innerWidth < 992) {
      window.toggleMobileFilters();
    }
    filterContainer = document.getElementById('provider-filters');
    filters = Object.keys(window.filters);
    filterContainer.classList.remove('filters-active');
    for (key in window.filters) {
      if (key !== 'hours' && key !== 'search_address') {
        delete window.filters[key];
      }
    }
    checkboxes = document.querySelectorAll('input[type="checkbox"], input[type="radio"]');
    checkboxes.forEach(function(checkbox) {
      if (checkbox.checked) {
        return checkbox.checked = false;
      }
    });
    filters = document.querySelectorAll(".filter");
    filters.forEach(function(filter) {
      return filter.style.border = "unset";
    });
    filterCounter = document.querySelectorAll('.filter-counter');
    filterCounter.forEach(function(filtercount) {
      return filtercount.textContent = "";
    });
    if (event.currentTarget.id === 'clear-filters') {
      event.currentTarget.classList.toggle('d-none');
    }
    map.updateMap(true);
    if (!document.querySelector('#clear-filters').classList.contains('d-none')) {
      return document.querySelector('#clear-filters').classList.add('d-none');
    }
  };

  return FilterProcessor;

}).call(this);

export default FilterProcessor;

var Marker;

import InfoWindow from './info_window';

Marker = class Marker {
  constructor(provider, map) {
    this.map = map;
    this.provider = provider;
  }

  currentMap() {
    return this.map;
  }

  addMarkerFor(provider) {
    var icon, infoNode, marker;
    infoNode = this.buildInfoNodeFor(provider);
    icon = this.iconFor(provider);
    marker = this.addMarker({
      id: provider.id,
      icon: icon,
      position: {
        lat: provider.latitude,
        lng: provider.longitude
      }
    });
    return this.addInfoWindowFor(provider, marker, infoNode);
  }

  addMarker(marker) {
    var newMarker, scrollContainer;
    newMarker = new google.maps.Marker(marker);
    newMarker.setMap(this.currentMap());
    scrollContainer = document.getElementById('provider-info-sidebar');
    newMarker.addListener('click', (e) => {
      var providerElement, scrollSource;
      providerElement = document.getElementById(`provider-id-${marker.id}`);
      // Scroll for desktop
      scrollSource = providerElement.closest('.marker-provider-info-wrapper');
      scrollSource.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "nearest"
      });
      const container = providerElement.closest('.container');
      if (container) {
        container.classList.add('highlight');
        setTimeout(() => {
          container.classList.remove('highlight');
        }, 500);
      }
    });
    window.markers.push(newMarker);
    return newMarker;
  }

  iconFor(provider) {
    var pins;
    // Maps directly to enum
    pins = ["/static/images/daycare_center_pin.png", "/static/images/daycare_home_pin.png"];
    return pins[provider.state_license_type];
  }

  // pins = "/static/images/daycare-pin-updated.png"
  buildInfoNodeFor(provider) {
    var domID, instance;
    domID = `provider-id-${provider.id}`;
    if (!document.getElementById(domID)) {
      instance = new InfoWindow(provider).getSidebarNode(domID);
      document.querySelector('#provider-info-sidebar').appendChild(instance);
    }
    return {
      htmlNode: instance,
      id: provider.id,
      domID: domID
    };
  }

  closeCurrentPin() {
    if (window.currentPin) {
      window.currentPin.infowindow.close(this.currentMap(), window.currentPin.marker);
      return window.currentPin = null;
    }
  }

  addInfoWindowFor(provider, marker, infoNode) {
    var content, infowindow, instance, sidebarInfoNode;
    instance = new InfoWindow(provider).getMarkerNode();
    content = instance.querySelector('.marker-provider-info-wrapper');
    infowindow = new google.maps.InfoWindow({
      content: content
    });
    sidebarInfoNode = document.getElementById(infoNode.domID);
    // Force show to inspect dom
    //infowindow.open(@currentMap, marker)
    //foobar
    sidebarInfoNode.addEventListener('click', (e) => {
      if ((typeof screen !== "undefined" && screen !== null) && window.innerWidth > 992) {
        this.closeCurrentPin();
        window.currentPin = {
          infowindow: infowindow,
          marker: marker
        };
        return infowindow.open({
          map: this.currentMap,
          anchor: marker,
          shouldFocus: false
        });
      }
    });
    marker.infowindow = infowindow;
    return marker.addListener('click', () => {
      this.closeCurrentPin();
      window.currentPin = {
        infowindow: infowindow,
        marker: marker
      };
      return infowindow.open({
        map: this.currentMap(),
        anchor: marker,
        shouldFocus: false
      });
    });
  }

};

export default Marker;

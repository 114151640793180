var MapHandler;

import FilterProcessor from './filter_processor';

import Map from './map';

MapHandler = class MapHandler {
  constructor() {
    var cachedAddress, searchAddress;
    this.geoSuccess = this.geoSuccess.bind(this);
    this.geoFailure = this.geoFailure.bind(this);
    this.buildMap = this.buildMap.bind(this);
    if ((searchAddress = queryString.parse(location.search)['search_address'])) {
      if ((cachedAddress = sscache.get(searchAddress))) {
        console.log(cachedAddress);
        this.geoSuccess({
          coords: {
            latitude: cachedAddress.lat,
            longitude: cachedAddress.lng
          }
        }, false);
      } else {
        this.geoFailure();
      }
    } else {
      this.setCenterByGeoLocation();
    }
  }

  setCenterByGeoLocation() {
    var cachedGeolocation;
    // reset text search for page navigation reload
    window.text_search_address = null;
    if ((cachedGeolocation = sscache.get('cachedGeolocation'))) {
      return this.geoSuccess(cachedGeolocation);
    } else {
      if (navigator.geolocation) {
        return navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoFailure, {
          maximumAge: 1800000
        });
      } else {
        return this.geoFailure(null);
      }
    }
  }

  geoSuccess(position, setReverse = true) {
    var lat, lng;
    lat = position.coords.latitude;
    lng = position.coords.longitude;
    this.buildMap({
      lat: lat,
      lng: lng
    });
    window.map = new Map(window.googleMap);
    if (setReverse) {
      map.reverseGeocodeSearchCenter();
    }
    map.updateMap(true);
    sscache.set('cachedGeolocation', {
      coords: {
        latitude: lat,
        longitude: lng
      }
    }, 15);
    document.getElementById("traffic_log_latlng").value = `${lat}, ${lng}`;
    return window.latlng = {
      lat: lat,
      lng: lng
    };
  }

  geoFailure(error) {
    var cachedGeolocation, lat, lng;
    if ((cachedGeolocation = sscache.get('cachedGeolocation'))) {
      return this.geoSuccess(cachedGeolocation);
    } else {
      lat = 46.7296;
      lng = -94.6859;
      this.buildMap({
        lat: lat,
        lng: lng
      });
      window.map = new Map(window.googleMap);
      return map.updateMap(true);
    }
  }

  buildMap(center) {
    var styles;
    styles = [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "poi.business",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "poi.attraction",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "transit",
        elementType: "labels",
        stylers: [
          {
            visibility: "off"
          }
        ]
      }
    ];
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 5,
      center: center,
      styles: styles
    });
    return window.googleMap = this.map;
  }

};

window.mapHandler = new MapHandler();

if (window.googleMap) {
  window.map = new Map(window.googleMap);
}

window.filterProcessor = new FilterProcessor();

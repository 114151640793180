var InfoWindow;

InfoWindow = class InfoWindow {
  constructor(provider) {
    this.provider = provider;
  }

  getProvider() {
    return this.provider;
  }

  getTemplate() {
    return document.getElementById('provider-template');
  }

  getProviderPath() {
    var query;
    query = Object.assign({}, window.filters);
    query['provider-distance'] = this.provider.distance;
    query = queryString.stringify(query);
    return `/providers/${this.provider.id}?${query}`;
  }

  getSidebarNode(domID = null) {
    const provider = this.getProvider();
    const instance = this.getTemplate().content.cloneNode(true);
    instance.querySelector('.provider-profile-image').innerHTML = `<img class='object-fit-cover rounded-2' src='${provider.profile_image_url}'>`;
    this.setGeoPin(instance, provider);
    if (domID) {
      instance.querySelector('.marker-provider-info').id = domID;
    }
    instance.querySelector('.provider-name').textContent = provider.name;
    instance.querySelector('turbo-frame').setAttribute('id', `favorite_toggle_provider_${provider.id}`);
    this.providerOpenings(provider, instance)
    // TODO: Hide distance for text search results
    instance.querySelector('.provider-distance').textContent = provider.distance;
    instance.querySelector('.provider-link').setAttribute('href', this.getProviderPath());
    if (instance.querySelector('.add-remove-favorite')) {
      let base_path = instance.querySelector('turbo-frame > form').getAttribute('action');
      base_path = base_path.replace(/provider_id/, provider.id);
      const path_with_distance = base_path + `?distance=${provider.distance}`;
      instance.querySelector('turbo-frame > form').setAttribute('action', path_with_distance);
      if (provider.favorited) {
        const favorited_icon = instance.querySelector('.add-remove-favorite > img').getAttribute('data-favorited-icon');
        instance.querySelector('.add-remove-favorite > img').src = favorited_icon;
      }
      instance.querySelector('.add-remove-favorite').setAttribute('action', `/users/provicers_favorites/${provider.id}`);
    }
    instance.querySelector('.marker-provider-info-wrapper').setAttribute('data-providerurl', this.getProviderPath());
    return instance;
  }

  getMarkerNode() {
    var instance, provider;
    provider = this.getProvider();
    instance = this.getTemplate().content.cloneNode(true);
    this.providerOpenings(provider, instance)
    instance.querySelector('.provider-profile-image').innerHTML = `<img class='object-fit-cover rounded-2' style="width: 100%; height: 100%;" src='${provider.profile_image_url}'>`;
    instance.querySelector('.provider-name').textContent = provider.name;
    instance.querySelector('.provider-distance').textContent = provider.distance;
    this.setGeoPin(instance, provider);
    instance.querySelector('.provider-link').setAttribute('href', this.getProviderPath());
    instance.addEventListener('click', function() {
      return window.location(this.getProviderPath());
    });
    return instance;
  }

  providerOpenings(provider, instance) {
    if ((provider.openings != null) && provider.openings.length > 0) {
      instance.querySelector('.provider-opening').classList.remove('d-none');
    }
  }

  setGeoPin(instance, provider) {
    const geopins = ["/static/images/daycare_center_pin.png", "/static/images/daycare_home_pin.png"];

    instance.querySelector('.geopin').setAttribute('src', geopins[`${provider.state_license_type}`]);
  }

};

export default InfoWindow;

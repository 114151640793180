(function() {
  var MapFilter;

  MapFilter = class MapFilter {
    constructor() {
      this.handleMap = this.handleMap.bind(this);
      this.mobileFilter = document.getElementById('mobile-filter');
      this.providerFilters = document.getElementById('provider-filters');
      this.filters = document.querySelectorAll('.filter');
      this.map = document.getElementById('map');
      this.setupFilters();
    }

    setupFilters() {
      return this.filters.forEach((elm) => {
        var label, menu;
        menu = elm.closest('.filter').querySelector('.menu');
        // Capture click
        label = elm.querySelector('.label');
        label.addEventListener('click', (e) => {
          e.currentTarget.classList.toggle('activated');
          menu.classList.toggle('show');
          if (window.innerWidth <= 992) {
            return this.handleMap(menu);
          }
        });
        // Capture click outside
        return document.addEventListener('click', (e) => {
          if (!elm.contains(e.target) && menu.classList.contains('show')) {
            menu.classList.remove('show');
            if (window.innerWidth <= 992) {
              this.handleMap(menu);
            }
            if (this.mobileFilter.classList.contains('activated')) {
              this.mobileFilter.classList.toggle('activated');
              return this.providerFilters.classList.toggle('mobile-filters-active');
            }
          }
        });
      });
    }

    handleMap(menu) {
      if (menu.classList.contains('show')) {
        return this.map.style.display = "none";
      } else {
        this.map.style.display = "block";
        return document.body.style.removeProperty('overflow');
      }
    }

  };

  new MapFilter();

}).call(this);
